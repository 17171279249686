import React from 'react';

function noop () {}

export class Input extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {id, name, value, label} = this.props;
    const {autofocus, required, pattern, minLength, maxLength} = this.props;
    const type = this.props.type || 'text';
    const onChange = typeof this.props.onChange === 'function' ? this.props.onChange : noop;
    const renderErrors = typeof this.props.renderErrors === 'function' ? this.props.renderErrors : noop;
    const position = this.props.position;
    let className = 'flex flex-col pb-gut w-full';
    if (position === 'left') className += ' md:pr-gut md:w-1/2';
    else if (position === 'right') className += ' md:pl-gut md:w-1/2';
    return (
      <div className={className}>
        <input className="input bg-none h-2gut border-b border-gray"
               type={type}
               placeholder=" "
               id={id}
               name={name}
               value={value}
               required={Boolean(required)}
               {...(pattern ? {pattern} : null)}
               {...(parseInt(minLength, 10) ? {minLength} : null)}
               {...(parseInt(maxLength, 10) ? {maxLength} : null)}
               autoFocus={Boolean(autofocus)}
               onChange={onChange}/>
        <label className="ph-label text-blue-dark"
               htmlFor={id}>{label}</label>
        {renderErrors(name)}
      </div>
    );
  }
}

export class Domain extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {id, name, value, prefix, postfix, label} = this.props;
    const {autofocus, required, pattern, minLength, maxLength} = this.props;
    const onChange = typeof this.props.onChange === 'function' ? this.props.onChange : noop;
    const renderErrors = typeof this.props.renderErrors === 'function' ? this.props.renderErrors : noop;
    return (
      <div className="flex flex-col w-full">
        <div className="flex items-end">
          <span className="text-sm tracking-none text-blue-dark mb-gut pb-5
                          border-b border-gray">{prefix}</span>
          <div className="flex-1 flex flex-col pb-gut">
            <input className="input bg-none h-2gut border-b border-gray"
                   type="text"
                   size={1}
                   placeholder=" "
                   id={id}
                   name={name}
                   value={value}
                   autoComplete="off"
                   required={Boolean(required)}
                   {...(pattern ? {pattern} : null)}
                   {...(parseInt(minLength, 10) ? {minLength} : null)}
                   {...(parseInt(maxLength, 10) ? {maxLength} : null)}
                   autoFocus={Boolean(autofocus)}
                   onChange={onChange}/>
            <label className="ph-label text-blue-dark"
                   htmlFor={id}>{label}</label>
          </div>
          <span className="text-sm tracking-none text-blue-dark mb-gut pb-5
                           border-b border-gray">{postfix}</span>
        </div>
        <div className="-mt-gut mb-gut">{renderErrors(name)}</div>
      </div>
    );
  }
}

export class Country extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {id, name, required, label, countries, selected} = this.props;
    const onChange = typeof this.props.onChange === 'function' ? this.props.onChange : noop;
    const renderErrors = typeof this.props.renderErrors === 'function' ? this.props.renderErrors : noop;
    return (
      <div className="flex flex-col pb-gut w-full">
        <select
          className="select bg-none h-2gut border-b border-gray appearance-none"
          id={id}
          name={name}
          required={Boolean(required)}
          onChange={onChange}>
        <option value="" disabled selected>Select a country</option>
        {countries.map(({code, name}) => <option value={code}>{name}</option>)}
        </select>
        <label className="ph-label text-blue-dark"
               htmlFor={id}>{label}</label>
        {renderErrors(name)}
      </div>
    );
  }
}
